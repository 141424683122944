import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppBar,
  Avatar,
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  Modal,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { RxArrowLeft } from 'react-icons/rx';
import { TfiWorld } from 'react-icons/tfi';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { icons } from '../../constants/channels';
import channelsStore from '../../store/channel/channel';
import publicationStore from '../../store/channel/publication';
import ChannelGeneral from './ChannelGeneral';
import ChannelMetadata from './ChannelMetadata';
import ModalBox from '../../components/Modal/Box';
import RedirectChannelStatus from '../../components/Modal/Channel/RedirectChannelStatus';
import SelectConnectionPage from '../../components/Modal/Channel/SelectConnectionPage';
import loadingStore from '../../store/loading';
import companyStore from '../../store/organisation/company';

const schema = Yup.object().shape({
  name: Yup.string().label('Channel Instance Name').trim().required('Required'),
  channelInstanceMetaDataTypeSet: Yup.array().of(
    Yup.object().shape({
      parameterType: Yup.string().required('Required'),
      name: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
      fieldSize: Yup.number().max(12, 'Please enter value less than equal to 12')
    })
  ),
  channelInstanceConfig: Yup.object().shape({
    configParams: Yup.array().of(
      Yup.object().shape({
        parameterValue: Yup.string().when('$connectNow', ([connectNow], schema) => {
          return connectNow ? schema.required('Required') : schema.notRequired();
        })
      })
    )
  }),
  channelGroups: Yup.array().required('Required').min(1, 'Required')
});

const AddOrEditChannel = () => {
  const { channelInstanceId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    state: { types },
    getChannelInstance,
    fetchChannelTypes,
    createChannel,
    updateChannel
  } = channelsStore;

  const {
    state: { metaCompanies },
    fetchUserMetaCompanies
  } = companyStore;

  const {
    fetchConnectionTypes,
    fetchConnectionTypeParameters,
    createConnectionParameters,
    connectChannel
  } = publicationStore;

  const defaultValues = {
    companyId: '',
    channelType: {
      id: ''
    },
    channelGroups: [],
    name: '',
    channelInstanceConfig: {
      connectionTypes: {
        id: ''
      },
      configParams: []
    },
    channelInstanceMetaDataTypeSet: []
  };
  const [values, setValues] = useState(defaultValues);
  const [connectNow, setConnectNow] = useState(false);
  const { handleSubmit, control, setValue, formState: { errors }, watch, trigger } = useForm({
    defaultValues,
    values,
    mode: 'onBlur',
    resolver: yupResolver(schema),
    context: { connectNow }
  });

  const [tabValue, setTabValue] = useState('one');
  const [existingChannel, setExistingChannel] = useState(null);
  const [selectedChannelTypeName, setSelectedChannelTypeName] = useState('');
  const [connectionTypes, setConnectionTypes] = useState([]);
  const [channelImageFile, setChannelImageFile] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [redirectConnectionStatusOpen, setRedirectConnectionStatusOpen] = useState(false);
  const [redirectConnectionStatus, setRedirectConnectionStatus] = useState(false);
  const [redirectConnectionText, setRedirectConnectionText] = useState('');
  const [redirectPages, setRedirectPage] = useState({});
  const [selectedChannelGroups, setSelectedChannelGroups] = useState(null);
  const isSelectConnectionPageOpen = Boolean(redirectPages.channelId);

  const companyIdWatch = watch('companyId');

  const loadExistingChannel = async () => {
    const response = await getChannelInstance(channelInstanceId);
    setExistingChannel(response);
    setValues(response);
    setImageId(response.channelImageUrl);
    setSelectedChannelTypeName(response.channelType.channelName);
    fetchConnectionTypeList(response.channelType.id, !response?.channelInstanceConfig?.status);
    setSelectedChannelGroups(response.channelGroups.map(g => g.id));
  };

  const fetchConnectionTypeList = (channelId, fetchParam) => {
    setValue('channelInstanceConfig.configParams', []);
    setConnectionTypes(null);
    fetchConnectionTypes(channelId).then(res => {
      if (fetchParam && res[0]) {
        setValue('channelInstanceConfig.connectionTypes.id', res[0].id);
        fetchConnectionParameter(channelId, res[0].id);
      }
      setConnectionTypes(res);
    });
  };

  const fetchConnectionParameter = (channelId, connectionTypeId) => {
    fetchConnectionTypeParameters(channelId, connectionTypeId).then(res => {
      setValue('channelInstanceConfig.configParams', res);
    });
  };

  useEffect(async () => {
    fetchUserMetaCompanies().then(res => setValue('companyId', res[0].id));
    if (!types.length) {
      await fetchChannelTypes();
    }

    const contextPath = window.location.href;
    if (contextPath.includes('?success=') || contextPath.includes('?failed=')) {
      // handle connection success or failure.
      const message = contextPath.split('?success=').at(1) || contextPath.split('?failed=').at(1);
      setRedirectConnectionStatusOpen(true);
      setRedirectConnectionStatus(contextPath.includes('?success='));
      setRedirectConnectionText(decodeURIComponent(message).replace(/\+|#_=_/g, ' '));
    } else if (contextPath.includes('pages')) {
      // handle profile selection for facebook and instagram.
      const decodedMessage = decodeURIComponent(document.location.search).replace(/\+|#_=_/g, ' ');
      const urlParams = new URLSearchParams(decodedMessage);
      const channelId = urlParams.get('channelId');
      const channelType = urlParams.get('channelType');
      const pages = urlParams.get('pages');

      const profileList = pages.split('~~').map(msg => {
        const newMsg = msg.split('~');
        return {
          id: newMsg[0],
          profileName: newMsg[1]
        };
      });
      setRedirectPage(
        {
          ...redirectConnectionText,
          channelId,
          channelType,
          profileList
        });
    }

    window.history.pushState({}, document.title, window.location.pathname);
  }, []);

  useEffect(() => {
    if (types?.length && !channelInstanceId) {
      setValue('channelType.id', types[0].id);
      setSelectedChannelTypeName(types[0].channelName);
      fetchConnectionTypeList(types[0].id, true);
    }
  }, [types]);

  useEffect(() => {
    if (channelInstanceId) {
      loadExistingChannel();
    }
  }, [channelInstanceId]);

  const handleChannelTypeChange = (value) => {
    fetchConnectionTypeList(value, true);
    setSelectedChannelTypeName(types.find(t => t.id === value).channelName);
  };

  const formOnSubmit = (data) => {
    loadingStore.setLoading(true);
    data.channelInstanceConfig.configParams?.forEach((cp, index) => {
      data.channelInstanceConfig.configParams[index] = {
        parameterTypes: {
          id: cp.parameterTypes ? cp.parameterTypes.id : cp.id
        },
        parameterValue: cp.parameterValue
      };
    });
    if (existingChannel) {
      handleUpdateChannel(data);
    } else {
      handleAddChannel(data);
    }
    loadingStore.setLoading(false);
  };

  const handleUpdateChannel = (data) => {
    if (!connectNow && existingChannel.channelInstanceConfig?.status) {
      connectOrDisconnect(channelInstanceId);
    } else {
      updateChannel(data, channelImageFile).then((res) => {
        if (res !== 0) {
          enqueueSnackbar('Channel updated successfully', { variant: 'success' });
          if (connectNow) {
            createConnection(data, channelInstanceId);
          }
        } else {
          enqueueSnackbar('Failed to update a channel', { variant: 'error' });
        }
      });
    }
  };

  const handleAddChannel = (data) => {
    createChannel(data, channelImageFile).then((res) => {
      if (res !== 0) {
        enqueueSnackbar('Channel added successfully', { variant: 'success' });
        if (connectNow) {
          createConnection(data, res.id);
        } else {
          navigate('/channels/manage-channels');
        }
        return;
      }
      enqueueSnackbar('Failed to add a channel', { variant: 'error' });
    });
  };

  const createConnection = (data, instanceId) => {
    createConnectionParameters({ id: instanceId, ...data })
      .then(() => {
        enqueueSnackbar('Publication setting has been stored succesfully!', {
          variant: 'success'
        });
        connectOrDisconnect(instanceId);
      })
      .catch((error) => {
        loadingStore.setLoading(false);
        enqueueSnackbar(error.response?.data, {
          variant: 'error'
        });
      });
  };

  const connectOrDisconnect = (instanceId) => {
    connectChannel(instanceId)
      .then((response) => {
        if (response.redirectUrl) {
          window.location.replace(response.redirectUrl);
        } else {
          if (response.status === true) {
            enqueueSnackbar('Channel connection has been established', {
              variant: 'success'
            });
            if (existingChannel) {
              setExistingChannel({
                ...existingChannel,
                channelInstanceConfig: {
                  ...existingChannel.channelInstanceConfig,
                  status: true
                }
              });
            } else {
              navigate('/channels/manage-channels/' + instanceId);
            }
          } else {
            enqueueSnackbar('Channel connection has been disconnected', {
              variant: 'success'
            });
            loadExistingChannel();
          }
        }
      })
      .catch((_error) => {
        enqueueSnackbar('Failed to connect channel', { variant: 'error' });
        navigate('/channels/manage-channels/' + instanceId);
        loadingStore.setLoading(false);
      });
  };

  const SelectConnectionPageModal = ({
    open,
    handleClose,
    redirectPages,
    loadInstances
  }) => {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <ModalBox>
            <SelectConnectionPage
              loadInstances={loadInstances}
              handleClose={handleClose}
              redirectPages={redirectPages}
            />
          </ModalBox>
        </Modal>
      </>
    );
  };

  return (
    <Container className='bg-slate-100'>
      <form className="w-100" onSubmit={handleSubmit(formOnSubmit)}>
        <Stack>
          <div className="flex items-center gap-2">
            <button
              className="text-xl"
              onClick={() => navigate('/channels/manage-channels')}
            >
              <RxArrowLeft />
            </button>
            <Breadcrumbs>
              <Link
                component="button"
                underline="hover"
                color="inherit"
                onClick={() => navigate('/channels/manage-channels')}
              >
                Manage Channel
              </Link>
              <Typography color="primary">
                {existingChannel ? 'Update Channel' : 'Add Channel'}
              </Typography>
            </Breadcrumbs>
          </div>
          <Grid container spacing={3} className='mt-3'>
            <Grid item className="justify-content-left gap-4 align-items-center py-5 md:flex-row" sm={12} md={6}>
              <InputLabel className="font-semibold">
                Select your channel type
              </InputLabel>
              <Controller
                name="channelType.id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="w-100 sm:w-1/2 mt-2"
                    style={{ backgroundColor: 'white' }}
                    placeholder="Select the channel type"
                    disabled = {!!existingChannel}
                    renderValue={(value) =>
                      types.find((t) => t.id === value)?.channelName
                    }
                    onChange={(e) => {
                      handleChannelTypeChange(e.target.value);
                      field.onChange(e);
                    }}
                  >
                    {types.map((channelType, index) => (
                      <MenuItem key={index} value={channelType.id}>
                        <div className="flex align-items-center gap-2">
                          <Avatar
                            sx={{
                              width: 35,
                              height: 35,
                              bgcolor: icons.find(
                                (i) => i.name === channelType.channelName
                              )
                                ? icons.find(
                                  (i) => i.name === channelType.channelName
                                ).color
                                : '#eee'
                            }}
                            className="p-1"
                          >
                            {icons.find(
                              (i) => i.name === channelType.channelName
                            )
                              ? (
                                  icons.find(
                                    (i) => i.name === channelType.channelName
                                  ).icon
                                )
                              : (
                                  <TfiWorld />
                                )}
                          </Avatar>
                          <span>{channelType.channelName}</span>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              ></Controller>
            </Grid>

            <Grid item className="justify-content-left gap-4 align-items-center py-5 md:flex-row" sm={12} md={6}>
              <InputLabel className="font-semibold">
                Select your company
              </InputLabel>
              <Controller
                name="companyId"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="w-100 sm:w-1/2 mt-2"
                    style={{ backgroundColor: 'white' }}
                    placeholder="Select your company"
                    disabled = {!!existingChannel}
                    renderValue={(value) => metaCompanies.find((t) => t.id === value)?.name || metaCompanies[0]?.name }
                    onChange={(e) => field.onChange(e) }
                  >
                    {metaCompanies?.map((comp, index) => (
                      <MenuItem key={index} value={comp.id}>
                        <div className="flex align-items-center gap-2">
                          <span>{comp.name}</span>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              ></Controller>
            </Grid>
          </Grid>
        </Stack>
        <Card
          className="w-100 mb-5"
          variant="outlined"
          style={{
            borderRadius: '4px'
          }}
        >
          <CardContent className="p-0">
            <AppBar
              position="static"
              style={{ backgroundColor: 'hsla(192, 68%, 41%, 0.08)' }}
            >
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => setTabValue(newValue)}
                variant="fullWidth"
              >
                <Tab label="General" value="one"></Tab>
                <Tab label="Meta Data (Optional)" value="two"></Tab>
              </Tabs>
            </AppBar>
            {tabValue === 'one' &&
              <ChannelGeneral
               selectedChannelTypeName={selectedChannelTypeName}
               connectionTypes={connectionTypes}
               imageId = {imageId}
               setImageId = {setImageId}
               setChannelImageFile={setChannelImageFile}
               control={control}
               errors={errors}
               existingChannel={existingChannel}
               selectedChannelGroups={selectedChannelGroups}
               setSelectedChannelGroups={setSelectedChannelGroups}
               setValue={setValue}
               companyIdWatch = {companyIdWatch}
               trigger = {trigger}
              />
            }
            {tabValue === 'two' &&
              <ChannelMetadata
                control={control}
                watch={watch}
                errors={errors}
                existingChannel={existingChannel}
              />
            }
          </CardContent>
        </Card>
        <Stack direction='row' spacing={2} justifyContent='flex-end'>
          <button
            variant='conatined'
            className='p-2 bg-white border-blue border-2 text-blue rounded-md'
            type='submit'
            onClick={ () => setConnectNow(false) }
          >
            { existingChannel ? (existingChannel.channelInstanceConfig?.status ? 'Disconnect' : 'Update & Connect Later') : 'Save & Connect Later'}
          </button>
          {!existingChannel && (
            <button
            variant='contained'
            className='p-2 text-white bg-blue rounded-md'
            type='submit'
            onClick={ () => setConnectNow(true) }
          >
            Save & Connect Now
          </button>
          )}
          {existingChannel && !existingChannel.channelInstanceConfig?.status && (
          <button
            variant='contained'
            className='p-2 text-white bg-blue rounded-md'
            type='submit'
            onClick={ () => setConnectNow(true) }
          >
            Update & Connect Now
          </button>
          )}
        </Stack>
      </form>
       <SelectConnectionPageModal
          open={isSelectConnectionPageOpen}
          handleClose={() => setRedirectPage({})}
          redirectPages={redirectPages}
        />
        <Modal
          open={redirectConnectionStatusOpen}
          onClose={() => setRedirectConnectionStatusOpen(false)}
        >
          <ModalBox>
            <RedirectChannelStatus
              open={redirectConnectionStatusOpen}
              text={redirectConnectionText}
              status={redirectConnectionStatus}
              handleClose={() => setRedirectConnectionStatusOpen(false)}
            />
          </ModalBox>
        </Modal>
    </Container>
  );
};

export default AddOrEditChannel;
